import React from 'react'
import Page from './Page'

export default function AnnotationEditor(props) {
  const { setAnnotations, pdf, pdfDocument, documentProperties, scale } = props

  const count = pdf?.numPages || 0

  const pages = []
  for(let i=1; i<=count; i++)
    pages.push(<Page 
      pdf={pdf} 
      key={i} 
      pageNumber={i} 
      setAnnotations={setAnnotations}
      pdfDocument={pdfDocument}
      scale={scale}
      documentProperties={documentProperties}
    />)

  return (
    <div>
      { pages }
    </div>
  )
}