import { rgb, setFillingColor, setFontAndSize, StandardFonts, PDFName } from 'pdf-lib'
import isUuid from 'uuid-validate'
import Short from 'short-uuid'

import getInstructions from './get-instructions'

const short = Short()

const BLACK = rgb(0, 0 ,0)
const DARK_BLUE = rgb(0, 0, .5)

const getSize = field => {
  const { acroField } = field

  const instructions = getInstructions(acroField)

  let fontSize = 0

  if (field.isMultiline())
  	fontSize = 10

  if (instructions && instructions.readonly)
  	fontSize = 6

  return fontSize
}

const getColor = field => {
  const { acroField } = field
  const instructions = getInstructions(acroField)

  if (instructions && instructions.readonly)
  	return BLACK

  return DARK_BLUE
}

const setFont = (form, field) => {
  const color = getColor(field)
  const size = getSize(field)

  const nda = [
    setFillingColor(color).toString(),
    setFontAndSize(StandardFonts.HelveticaBold, size).toString(),
  ].join(' ');

  field.acroField.setDefaultAppearance(nda)
}

const setName = (doc, form, field) => {
  const { acroField } = field
  const currentName = acroField.getFullyQualifiedName()

  try {
  	const is = isUuid(short.toUUID(currentName))
  	if (is)
  		return is
  } catch(e) {}

  const newName = short.generate()
  acroField.setPartialName(newName)
  const parent = acroField.getParent()

  if (parent) {
    /* 
     * Some attributes are inheritable by the parent.
     * If we want to remove the parent, we need to copy thise attributes to the child.
     * FT is Type of Weidget (Check box or radio or what)
     * Ff is flags for the widget
     * V is Value
     */
    const FT = acroField.getInheritableAttribute(PDFName.of('FT'))
    const Ff = acroField.getInheritableAttribute(PDFName.of('Ff'))
    const V = acroField.getInheritableAttribute(PDFName.of('V'))

    if (FT)
      acroField.dict.set(PDFName.of('FT'), FT)
  
    if (Ff)
      acroField.dict.set(PDFName.of('Ff'), Ff)

    if (V)
      acroField.dict.set(PDFName.of('V'), V)

    /**  we try to seperate the text fields from their parents
      *  however, removing all non-teminal node's  kids will result in it 
      *  being treated as a terminal node in pdf-lib
      *  this throws an error during flattening : 'Error: Expected instance of PDFName, but got instance of undefined'
      *  so we try to remove the parent if it has no remaining kids....
      *  for more info : see issue #288 on forms
    */
     acroField.setParent()
     const kids = parent.Kids();
     const index = kids.indexOf(acroField.ref);
     kids.remove(index);
     if (parent.Kids().size() === 0) {
       form.acroForm.removeField(parent);
     }
     const ref = doc.context.register(acroField.dict)
     form.acroForm.addField(ref)
   } 

  return newName
}

function process({doc, form, field, font }) {
  setFont(form, field, font)
  setName(doc, form, field)
}

export default process