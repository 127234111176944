import React from 'react'
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { isEqual } from 'lodash'

const lists = [
  [
    {
      label: 'Full Address',
      value: 'full_address'
    },

    {
      label: 'Line 1',
      value: 'street_address'
    },

    {
      label: 'Street Prefix',
      value: 'street_dir_prefix'
    },

    {
      label: 'Street Number',
      value: 'street_number'
    },

    {
      label: 'Street Name',
      value: 'street_name'
    },

    {
      label: 'Street Suffix',
      value: 'street_suffix'
    },

    {
      label: 'City',
      value: 'city'
    },

    {
      label: 'State',
      value: 'state'
    },

    {
      label: 'State Code',
      value: 'state_code'
    },

    {
      label: 'County',
      value: 'county'
    },

    {
      label: 'Unit Number',
      value: 'unit_number'
    },

    {
      label: 'Building Number',
      value: 'building_number'
    },

    {
      label: 'Postal Code',
      value: 'postal_code'
    },

    {
      label: 'Block Number',
      value: 'block_number'
    },

    {
      label: 'Lot Number',
      value: 'lot_number'
    },

    {
      label: 'Project Number',
      value: 'project_number'
    },

    {
      label: 'Subdivision',
      value: 'subdivision'
    },

    {
      label: 'MLS Area (Major)',
      value: 'mls_area_major'
    },

    {
      label: 'MLS Area (Minor)',
      value: 'mls_area_minor'
    }
  ],
  [
    {
      label: 'List Date',
      value: 'list_date'
    },

    {
      label: 'Expiration Date',
      value: 'expiration_date'
    },

    {
      label: 'List Price',
      value: 'list_price'
    },

    {
      label: 'Lease Price',
      value: 'lease_price'
    },

    {
      label: 'Property Type',
      value: 'property_type'
    },

    {
      label: 'MLS Number',
      value: 'mls_number'
    },

    {
      label: 'Year Built',
      value: 'year_built',
    }
  ],

  [
    {
      label: 'Contract Date',
      value: 'contract_date'
    },

    {
      label: 'Financing Due',
      value: 'financing_due'
    },

    {
      label: 'Inspection Due',
      value: 'inspection_date'
    },

    {
      label: 'Option Period',
      value: 'option_period'
    },

    {
      label: 'Posession Date',
      value: 'possession_date'
    },

    {
      label: 'T47 Due',
      value: 't47_due'
    },

    {
      label: 'Title Due',
      value: 'title_due'
    },

    {
      label: 'Closing Date',
      value: 'closing_date'
    },

    {
      label: 'Sales Price',
      value: 'sales_price'
    },

    {
      label: 'Lease Begin',
      value: 'lease_begin'
    },

    {
      label: 'Lease End',
      value: 'lease_end'
    },

    {
      label: 'Lease Application Date',
      value: 'lease_application_date'
    },

    {
      label: 'Lease Executed',
      value: 'lease_executed'
    },

    {
      label: 'Leased Price',
      value: 'leased_price'
    }
  ]
]

export default function Assignment(props) {
  const { onChange, context } = props

  return (
    <div>
      {lists.map((list, id) => {
        return (
          <ButtonGroup size="small" aria-label="xsmall button group" orientation="vertical">
            {list.map((item, key) => {
              const selected = item.value === context

              const variant = selected ? 'contained' : 'outlined'
              return (
                <Button
                  onClick = {() => onChange(item.value)}
                  variant={variant}
                >
                  {item.label}
                </Button>
              )
            })}
          </ButtonGroup>
        )
      })}
    </div>
  )
}
