import React, { useEffect, useState } from 'react'
import pdfjs from 'pdfjs-dist'
import { PDFDocument, PDFTextField } from 'pdf-lib'

import AnnotationEditor from './AnnotationEditor'
import Appbar from './Appbar'
import Pdf from './Pdf'

import processDocument from './process'
import getInstructions from './process/get-instructions'
import setInstructions from './process/set-instructions'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {checkFieldError, checkFieldWarning} from './checkFields'

import { API_URL } from './config'
import './App.css';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

pdfjs.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.2.228/pdf.worker.min.js'

const params = new URLSearchParams(window.location.search)

export default function App({logout}) {
  const [ form, setForm ] = useState(params.get('form')) 

  const [annotations, setAnnotations] = useState([]) // Currently selected annotations

  const [ pdf, setPdf ] = useState()   // PDFJS Document
  const [ pdfDocument, _setDocument ] = useState() // PDF-Lib Documentø
  const [ scale, setScale ] = useState(1.5);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarText, setSnackbarText] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  
  const handleSnackbarClose = () => {
    setOpenSnackbar(false)
  }
  
  class ChangeLog{
    constructor(documentProperties, changesArray=[], undoneArray=[]){
      this.changesArray = changesArray;
      this.undoneArray = undoneArray;
      this.documentProperties = documentProperties;
      this.canUndo = changesArray.length !== 0;
      this.canRedo = undoneArray.length !== 0;
    }

    addChange(change){
      this.changesArray.push(change);
      this.canUndo = true;
      this.undoneArray = [];
      this.canRedo = false;
    }

    undoChange(undo = true){
      let change
      if (undo){
        change = this.changesArray.pop();
      } else {
        change = this.undoneArray.pop();
      }
      
      if(!change){
        return;
      }

      if (undo){
        this.undoneArray.push(change);
        this.canRedo = true;
        if (this.changesArray.length === 0) {
          this.canUndo = false;
        }
      } else {
        this.changesArray.push(change);
        this.canUndo = true;
        if (this.undoneArray.length === 0) {
          this.canRedo = false;
        }
      }

      const fields = Object.keys(change.fields);
      let message = ''
      
      if(change.type === 'setInstructions'){
        for(const fieldName in change.fields){
          this.documentProperties.setFieldsInstructions([fieldName], change.fields[fieldName][undo ? 'prev' : 'current'], true)
        }
        message = `${undo ? 'undo' : 'set'} mapping for ${fields.length} fields: \n
        ${JSON.stringify(change.fields[fields[0]]['current'], 1, 1)}
        `;

      } else if(change.type === 'delete'){
        this.documentProperties.setFieldsState(fields, undo ? '' : 'deleted');
        if (undo){
          this.documentProperties.deletedFields = this.documentProperties.deletedFields.filter(field => !fields.includes(field));
        } else {
          this.documentProperties.deletedFields = this.documentProperties.deletedFields.concat(fields);
        }
        
        message = `${undo ? 'undo ' : ''}removing ${fields.length} fields\n`;
      }

      alert(message);
      saveDocument(this.documentProperties)
      if(change.type === 'delete'){
        window.location.reload();
      }
    }

    redoChange(){
      this.undoChange(false)
    }

    forgetDeletingChanges(){
      for(const change of this.changesArray){
        if(change.type === 'delete'){
          this.changesArray.splice(this.changesArray.indexOf(change), 1)
        }
      }
      for(const change of this.undoneArray){
        if(change.type === 'delete'){
          this.undoneArray.splice(this.undoneArray.indexOf(change), 1)
        }
      }
    }

    forgetChanges(){
      this.changesArray = []
      this.undoneArray = []
    }
  }

  class DocumentProperties{
    constructor(pdfDocument, mapping={}, deletedFields=[], changeLog={changesArray: [], undoneArray: []}, selectingMode=false){
      this.pdfDocument = pdfDocument;
      this.form = pdfDocument.getForm();
      this.mapping = mapping;
      this.deletedFields = deletedFields;
      this.selectingMode = selectingMode;
      this.changeLog = new ChangeLog(this, changeLog.changesArray, changeLog.undoneArray);
      this.canUndo = this.changeLog.canUndo;
      this.canRedo = this.changeLog.canRedo;
    }

    getSelectedFields(){
      const selectedFields = [];
      for (const fieldName in this.mapping) {
        if (this.mapping[fieldName]['state'] === 'selected') {
          selectedFields.push(fieldName);
        }
      }
      return selectedFields;
    }

    toggleFieldSelection(fieldName){
      const state = this.mapping[fieldName]['state'] === 'selected' ? '' : 'selected'
      this.setFieldsState([fieldName], state)
    }

    setFieldsState(fields, state) {
      for (const fieldName of fields) {
        this.mapping[fieldName]['state'] = state;
      }
      _setDocumentProperties(this)
    }

    removeSelectedFields(){
      const selectedFields = this.getSelectedFields()
      this.setFieldsState(selectedFields, 'deleted');
      this.deletedFields = this.deletedFields.concat(selectedFields);
      const change = {
        fields: {},
        type: 'delete'
      }
      
      for (const fieldName of selectedFields) {
          try{
            const field = this.form.getField(fieldName);
            const instructions = getInstructions(field.acroField);
            change.fields[fieldName] = {'prev': instructions, 'current': ''}
            this.form.removeField(field);
            setAnnotations([])
          } catch(e){
            //the field has already been deleted
          }
      }
      this.changeLog.addChange(change);
      _setDocumentProperties(this)
      saveDocument(this)
    }

    checkFieldsInstructions(fields){
      if(!fields[0]){
        return;
      }
      const instructions = getInstructions(this.form.getField(fields[0]).acroField);
      let result = checkFieldError(instructions)
      if(result.state !== 'success') {
        this.showSnackbar(fields[0] + ' is a ' + result.message, result.state)
        return result
      }
      result = checkFieldWarning(instructions)
      if(result.state !== 'success') {
        this.showSnackbar(result.message, result.state)
        return result
      }
      return result
    }

    showSnackbar(message, severity) {
      setOpenSnackbar(true)
      setSnackbarSeverity(severity)
      setSnackbarText(message)
    }

    setFieldsInstructions(fields, instructions, isChangeLog = false) {
      const change = {
        fields: {},
        type: 'setInstructions'
      }

      for (const fieldName of fields) {
        const field = this.form.getField(fieldName)
        setInstructions(field.acroField, instructions)
        change.fields[fieldName] = {'prev': this.mapping[fieldName]['instructions'], 'current': instructions}
        this.mapping[fieldName]['instructions'] = instructions
      }

      if (!isChangeLog){
        this.changeLog.addChange(change);
      }

      _setDocumentProperties(this)
      saveDocument(this)
    }

    toggleSelectingMode(){
      this.setFieldsState(this.getSelectedFields(), '');
      this.selectingMode = !this.selectingMode;
      setAnnotations([]);
      _setDocumentProperties(this)
    }

    undoChange(){
      this.changeLog.undoChange();
      this.canUndo = this.changeLog.canUndo;
      this.canRedo = this.changeLog.canRedo;
    }

    redoChange(){
      this.changeLog.redoChange();
      this.canUndo = this.changeLog.canUndo;
      this.canRedo = this.changeLog.canRedo;
    }

    forgetDeletingChanges(){
      this.changeLog.forgetDeletingChanges()
      _setDocumentProperties(this)
      saveDocument(this)
    }

    forgetChanges(){
      let confirmed = window.confirm("Are you sure you want to remove all changes and sync with production?")
      if (confirmed){
        this.changeLog.forgetChanges()
        localStorage.removeItem(params.get('form'))
        window.location.reload();
      }
    }
  }
  var [ documentProperties, setDocumentProperties ] = useState();

  function _setDocumentProperties(documentProps){
    const newProps = new DocumentProperties(documentProps.pdfDocument, documentProps.mapping, documentProps.deletedFields, documentProps.changeLog, documentProps.selectingMode);
    setDocumentProperties(newProps);
  }

  function saveDocument(documentProperties){
    localStorage.setItem(params.get('form'), JSON.stringify({mapping: documentProperties.mapping, deletedFields: documentProperties.deletedFields, changeLog: {changesArray: documentProperties.changeLog.changesArray, undoneArray: documentProperties.changeLog.undoneArray}}) )
  }

  function loadDocument(pdfDocument){
      const Document = JSON.parse(localStorage.getItem( params.get('form') ));

      if (!Document){
        return null
      }

      const documentProps = new DocumentProperties(pdfDocument, Document.mapping, Document.deletedFields, Document.changeLog ?? {changesArray: [], undoneArray: []})

      const form = pdfDocument.getForm();
      const fields = {}
      form.getFields().forEach(field=>{fields[field.acroField.getPartialName()] = field})
      for (const fieldName in documentProps.mapping) {
        try{
            const field = fields[fieldName];
            setInstructions(field.acroField, documentProps.mapping[fieldName]['instructions']);
        } catch(e){
            //the form does not have a field with this fieldName
        }
      }
      for (const fieldName of documentProps.deletedFields){
        try{
          const field = fields[fieldName];
          form.removeField(field);
        } catch(e){
          //the field has already been deleted
        }
    }
    return documentProps
  }

  //trys to load the mapping saved in localStorage
  //else it will create a new mapping from the document
  function getMapping(pdfDocument){
    const form = pdfDocument.getForm();
    const fields = form.getFields()
    const DocumentProps = loadDocument(pdfDocument) ?? new DocumentProperties(pdfDocument);
    for (const field of fields) {
      const fieldName = field.acroField.getPartialName()
      try{
        if ((!field instanceof PDFTextField || DocumentProps.mapping[fieldName])){
          continue
        }
        const instructions = getInstructions(field.acroField);
        DocumentProps.mapping[fieldName] = {'instructions': instructions, state: ''}
      } catch(e){
        
      }
    }
    DocumentProps.selectingMode = false;
    syncDocument(pdfDocument, DocumentProps)
  return DocumentProps;
  }

  async function syncDocument(doc, documentProperties){
    const documentForm = doc.getForm();
    const fields = {}
    documentForm.getFields().forEach(field=>{fields[field.acroField.getPartialName()] = field})
    
    let changedFields = [];
    for(const change of documentProperties.changeLog.changesArray){
      if (change.type === 'setInstructions'){
        changedFields = changedFields.concat(Object.keys(change.fields))
      }
    }
    
    for (const fieldName in fields) {
      try{
        const field = fields[fieldName];
        if (!field instanceof PDFTextField || changedFields.includes(fieldName)){
          continue
        }
        const instructions = getInstructions(field.acroField);
        documentProperties.mapping[fieldName] = {'instructions': instructions, state: ''}
      } catch(e){
        console.log(e)
      }
    }
    saveDocument(documentProperties)
  }

  useEffect(() => {
    const load = async () => {
      const pdf = await pdfjs.getDocument({data: await pdfDocument.save()})
      setPdf(pdf)
    }
    
    if (pdfDocument)
      load()

  }, [pdfDocument])

  useEffect(() => {
    const load = async () => {
      const url = `${API_URL}/pdf/${form}.pdf?id=${Math.random()}`
      const res = await fetch(url);
      const data = await res.arrayBuffer()
      const doc = await PDFDocument.load(data)
      await processDocument(doc)
      setDocumentProperties(getMapping(doc))
      _setDocument(doc)
    }

    load()
  }, [form])

  if (!pdf){
    return null
  }

  return (
    <div>
      <Appbar form={form} setForm={setForm} pdfDocument={pdfDocument} setScale={setScale} documentProperties={documentProperties} logout={logout} />
      <AnnotationEditor form={form} annotations={annotations} setAnnotations={setAnnotations} pdfDocument={pdfDocument} documentProperties={documentProperties}/>
      <Pdf form={form} setAnnotations={setAnnotations} pdf={pdf} pdfDocument={pdfDocument} scale={scale} documentProperties={documentProperties}/>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarText}
        </Alert>
      </Snackbar>
    </div>
  )
}