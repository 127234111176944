import React, { useState, useRef, useEffect } from 'react'
import TextField from '@mui/material/TextField';

export default function Instructions(props) {
  const { instructions, setInstructions } = props

  const [ value, setValue ] = useState(instructions ? JSON.stringify(instructions, 4, 4) : '')
  const [ isError, setError ] = useState(false)

  const handleChange = e => {
    const { value } = e.target

    setValue(value)

    try {
      const json = JSON.parse(value)
      setInstructions(json)
      setError(false)
    } catch(e) {
      setError(true)
    }
  }

  useEffect(() => {
    setValue(instructions ? JSON.stringify(instructions, 4, 4) : '')
  }, [instructions])

  return (
    <div style={{padding: '10px', backgroundColor: '#00000010'}}>
      <TextField 
        color={isError?'secondary':'primary'}
        rowsMin={3}
        placeholder="JSON"
        label="JSON"
        value={ value }
        onChange={handleChange}
        fullWidth 
        multiline
      />
    </div>
  )
}