import React from 'react'

import sign from './Sign.png'
import date from './Date.png'
import initials from './Initials.png'
import checkbox from './Checkbox.png'
import textbox from './Textbox.png'
import empty from './Empty.png'
import {checkFieldError, checkFieldWarning} from '../../../../checkFields'

const assignments = {
  Signature: {
    width: 78,
    url: sign
  },

  Date: {
    width: 98,
    url: date
  },

  Initials: {
    width: 52,
    url: initials
  },

  Textbox: {
    width: 112,
    url: textbox
  },

  Checkbox: {
    width: 21,
    url: checkbox
  },

  Empty: {
    width: 110,
    url: empty
  }
}

function Annotation(props) {
  const { annotation, documentProperties, left, top, setAnnotations, instructions } = props

  const clicked = () => {
    if(documentProperties.selectingMode){
      documentProperties.toggleFieldSelection(annotation.fieldName);
      setAnnotations(annotations=>{const index = annotations.indexOf(annotation)
        if(index === -1){
          return annotations.concat([annotation])
        }
        annotations.splice(index, 1)
        return annotations})
    } else{
      documentProperties.checkFieldsInstructions([annotation.fieldName]);
      setAnnotations([annotation])
    }
  }

  const { width, url } = assignments[instructions.assignment ?? 'Empty'] || {}

  const style = {
    width,
    top,
    left,
    cursor: 'pointer',
    position: 'absolute',
    border: (documentProperties.selectingMode && documentProperties.mapping[annotation.fieldName]?.state === 'selected') ? '3px solid #ff000080' : (checkFieldError(instructions).state !== 'success' || checkFieldWarning(instructions).state!=='success') ?  '3px solid #0000ff80' : '0 solid black',
    display: documentProperties.mapping[annotation.fieldName]?.state === 'deleted' ? 'none' : 'block'
  }

  if (!url)
    return

  return (
    <img alt={annotation.fieldName} title={annotation.fieldName} src={url} onClick={clicked} style={style} />
  )
}

export default React.memo(Annotation)