import App from './App'
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import Login from './login';
import useCredentials from './store';
import {loginUser} from './login';

async function getCredentials(){
  const credentialsString = localStorage.getItem('credentials');
  const credentials = JSON.parse(credentialsString);
  if(credentials?.username && credentials?.password){
    return await loginUser(credentials) ? credentials : undefined;
  } else {
    console.log(credentials)
  }
};

function Mapper() {
  const {credentials, setCredentials } = useCredentials();
  useEffect(()=>{
    getCredentials().then((credentials) => credentials ? setCredentials(credentials) : undefined);
    }, [])

  function logout(){
    setCredentials({})
    localStorage.removeItem('credentials');
    window.location.reload();
  }

  if(credentials && JSON.parse(localStorage.getItem('credentials'))?.username){
    return (
      <App logout = {logout}/>
    );
  } else{
    return <Login setCredentials = {setCredentials} />
  }
}

ReactDOM.render(
  <Mapper />,
  document.getElementById('root')
)