import { PDFTextField } from 'pdf-lib'
import processTextField from './text-field'
import removeInvalidFields from './remove-invalid-fields'
import fixOrphanWidgets from './fix-orphan-widgets'
import {rearangeFields} from './arrange_fields'
import test from './test'

async function process(doc) {
  const form = doc.getForm()

  fixOrphanWidgets(doc, form)
  removeInvalidFields(doc, form)
  rearangeFields(doc)
  const fields = {}
  form.getFields().forEach(field=>{fields[field.acroField.getPartialName()] = field})
  
  for(const fieldName in fields) {
    const field = fields[fieldName]
		if (!(field instanceof PDFTextField))
			continue
  	processTextField({doc, form, field})
  }

  try { 
    test(doc)
    } catch(e) {
    throw Error(`Flattening failed: ${e.message}`)
  }

  return doc

}

export default process