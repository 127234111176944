export const cheatSheet = [
    { type: 'Number', format: 'Currency' },
    { type: 'Assignment', role: [ 'Seller' ], assignment: 'Checkbox' },
    {
      type: 'Assignment',
      role: [ 'Seller', 'SellerPowerOfAttorney' ],
      assignment: 'Initials'
    },
    { type: 'Context', context: 'full_address' },
    { type: 'Assignment', assignment: 'Textbox', role: [ 'Seller' ] },
    {
      type: 'Assignment',
      role: [ 'Buyer', 'BuyerPowerOfAttorney' ],
      assignment: 'Initials'
    },
    { type: 'Date', format: 'MMMM DD, YYYY' },
    { type: 'Assignment', role: [ 'Tenant' ], assignment: 'Textbox' },
    {
      type: 'Assignment',
      role: [ 'Seller', 'SellerPowerOfAttorney' ],
      assignment: 'Signature'
    },
    { type: 'Role', role: [ 'PrimaryAgent' ], attributes: [ 'stamp' ] },
    {
      type: 'Assignment',
      role: [ 'Seller', 'SellerPowerOfAttorney' ],
      assignment: 'Date'
    },
    {
      type: 'Assignment',
      role: [ 'Buyer', 'BuyerPowerOfAttorney' ],
      assignment: 'Signature'
    },
    {
      type: 'Roles',
      role: [ 'Seller' ],
      attributes: [ 'legal_full_name' ]
    },
    {
      type: 'Role',
      role: [ 'PrimaryAgent' ],
      attributes: [ 'legal_full_name' ]
    },
    {
      type: 'Assignment',
      role: [ 'Tenant', 'TenantPowerOfAttorney' ],
      assignment: 'Initials'
    },
    {
      type: 'Assignment',
      role: [ 'Buyer', 'BuyerPowerOfAttorney' ],
      assignment: 'Date'
    },
    {
      type: 'Role',
      role: [ 'SellerAgent' ],
      attributes: [ 'legal_full_name' ]
    },
    {
      type: 'Assignment',
      role: [ 'Landlord', 'LandlordPowerOfAttorney' ],
      assignment: 'Initials'
    },
    {
      type: 'Role',
      role: [ 'Seller' ],
      attributes: [ 'legal_full_name' ]
    },
    {
      type: 'Role',
      role: [ 'SellerAgent' ],
      attributes: [ 'office_name', 'agent.office.name' ]
    }
  ]