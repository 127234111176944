import React from 'react'
import {FormatInput, FormatInputDefaults} from '../../inputs';

export default function Date(props) {
  const { onChange, format } = props

  return (
    <FormatInput onChange={onChange} format={format} label={'Format'} margins={FormatInputDefaults.margins}/>
  )
}
