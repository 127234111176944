import React, { useState, useEffect } from 'react'
import pdfjs from 'pdfjs-dist'
import getInstructions from '../../../process/get-instructions'
import { useInView } from 'react-intersection-observer'

import Text from './Text'
import Assignment from './Assignment'


function Annotation(props) {
  const { annotation, viewport, setAnnotations, pdfDocument, documentProperties } = props
  const [ instructions, setInstructions ] = useState()
  const [ width, setWidth ] = useState()
  const [ height, setHeight ] = useState()
  const [ left, setLeft ] = useState()
  const [ top, setTop ] = useState()
  const [ isLoading, setIsLoading ] = useState(false)

  const [ref, inView] = useInView()

  useEffect(() => {
    if (!inView)
      return

    // TODO: It needs to be communicated with users where the field is not found or there's an exception
    try {
      const field = pdfDocument.getForm().getFieldMaybe(annotation.fieldName)
      if (!field)
        return

      const rect = pdfjs.Util.normalizeRect(
        viewport.convertToViewportRectangle(annotation.rect)
      )

      setHeight(Math.floor(rect[3] - rect[1]))
      setWidth(Math.floor(rect[2] - rect[0]))

      setLeft(rect[0])
      setTop(rect[1])

      setInstructions(getInstructions(field.acroField))

      setIsLoading(false)
    } catch(e) {

    }

  }, [annotation, pdfDocument, viewport, inView])

  if (annotation.fieldType !== 'Tx')
    return null

  if (isLoading)
    return null

  const style = {
    position: 'absolute',
    top,
    left,
    width: '1px',
    height: '1px',
  }

  return (
    <>
      <div style={style} ref={ref} />

      { instructions?.type === 'Assignment' &&  
        <Assignment 
          instructions={instructions} 
          annotation={annotation} 
          documentProperties={documentProperties}
          setAnnotations={setAnnotations}
          viewport={viewport}  
          width={width}
          height={height} 
          left={left}
          top={top}
        /> 
      }

      { instructions?.type !== 'Assignment' &&  
        <Text 
          instructions={instructions} 
          annotation={annotation} 
          setAnnotations={setAnnotations}
          viewport={viewport} 
          width={width}
          height={height} 
          left={left}
          top={top}
          pdfDocument={pdfDocument}
          fieldName={annotation.fieldName}
          documentProperties={documentProperties}
        /> 
      }
    </>
  )
}

export default React.memo(Annotation)