import React from 'react'
import { pick } from 'lodash'
import Format from '../../properties/Format/Number'

export default function Instructions(props) {
  const { instructions, setInstructions } = props

  const updateInstructions = newValues => {
    setInstructions({
     ...pick(instructions, [
      'type',
      'format'
     ]),
     ...newValues
   })
  }

  const handleFormatChange = value => {
    updateInstructions({
      format: value ? 'Currency' : undefined
    })
  }

  return (
    <div style={{padding: '10px', backgroundColor: '#00000010'}}>
      <Format format={instructions?.format === 'Currency'} onChange={handleFormatChange} />
    </div>
  )
}