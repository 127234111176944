import React from 'react'
import {MultipleSelection, MultipleSelectionDefaults} from '../../inputs';

const list = [
  "Seller",
  "SellerPowerOfAttorney",
  "SellerLawyer",
  "SellerAgent",
  "CoSellerAgent",
  "InternalSeller",
  "SellerReferral",
  "SellerBroker",
  "PrimaryAgent",
  "Title",
  "Buyer",
  "BuyerPowerOfAttorney",
  "BuyerLawyer",
  "BuyerAgent",
  "CoBuyerAgent",
  "BuyerReferral",
  "ExternalBuyerAgent",
  "InternalBuyerAgent",
  "InternalBuyer",
  "BuyerBroker",
  "Landlord",
  "LandlordPowerOfAttorney",
  "Tenant",
  "TenantPowerOfAttorney",
  "BuyerSalesManager",
  "SellerSalesManager"
]

export default function Assignment(props) {
  const { onChange, roles } = props
  return (
    <MultipleSelection list={list} onChange={onChange} selected={roles} columnsNumber={5} label={'Roles'} orientation={'row'} margins={MultipleSelectionDefaults.margins} columnsMargins={MultipleSelectionDefaults.columnsMargins} buttonsMargins={MultipleSelectionDefaults.buttonsMargins}/>
  )
}
