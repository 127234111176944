import React, { useState, useEffect } from 'react'
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import { AiOutlineEye, AiOutlineSave, AiOutlineCloudSync, AiOutlineDownload, AiOutlineDelete, AiOutlineZoomOut,AiOutlineZoomIn, AiOutlineUndo, AiOutlineRedo, AiOutlineLogout } from 'react-icons/ai';
import { BsToggleOff, BsToggleOn } from 'react-icons/bs';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {rearangeFields, testArrangment} from '../process/arrange_fields'

import { API_URL } from './../config'

import Save from './Save'

function Appbar({form, setForm, pdfDocument, logout, setScale, documentProperties}) {
	const [showSave, setShowSave] = useState(false)
	const [formsList, setFormsList] = useState([])
	const [formName, setFormName] = useState('')

	const open = async () => {
		const blob = new Blob([await pdfDocument.save()], {type: 'application/pdf'})
		const url = URL.createObjectURL(blob)
		window.open(url)
	}

	const download = async () => {
		const blob = new Blob([await pdfDocument.save()], {type: 'application/pdf'})
		const a = document.createElement('a');
		document.body.appendChild(a);
		const url = window.URL.createObjectURL(blob);
		a.href = url;
		a.download = formName;
		a.click();
		setTimeout(() => {
			window.URL.revokeObjectURL(url);
			document.body.removeChild(a);
		}, 10)
	}

	const divStyle = {
		display: 'flex',
		justifyContent: 'space-between',
		top: 0,
		right: 0,
		left: 0,
		zIndex: 2
	}
	const appbarStyle = {
		background: '#00B286',
		zIndex: 2
	}

	useEffect(() => {
		const load = async () => {
			setFormsList((await getFormsList()).formsList);
			setFormName(getFormName(formsList, form));
		}
	
		load()
	  }, [form])

	const handleFormChange =(e, value)=>{
		if(!value?.id){
			return 
		}
		setForm(value.id);
	}

	return (
		<div style = {divStyle} >
			<Save open={showSave} setShowSave={setShowSave} form={form} pdfDocument={pdfDocument} documentProperties={documentProperties}/>
			<AppBar position="fixed" style = {appbarStyle}>
				<Toolbar style={{display:'flex', justifyContent:"space-between", width:'90%'}}>
				<div>
					<Autocomplete
					disablePortal
					id = "combo-box-demo"
					className = "autoComplete"
					getOptionLabel={(option) => option.name}
					options={ formsList }
					onChange={ handleFormChange }
					size='small'
					value={formsList.find(option=>option.id=form)}
					loading= { formsList.length === 0 }
					renderInput={(params) => <TextField {...params} label="Form" />}
					/>
					<Button className = "btn btn-appbar" onClick={ () => setShowSave(true) }><AiOutlineSave size='2em'/>&nbsp;Save</Button>
					<Button className = "btn btn-appbar" onClick={ open }><AiOutlineEye size='2em'/>&nbsp;Preview</Button>
					<Button className = "btn btn-appbar" onClick={ () => documentProperties.forgetChanges() }><AiOutlineCloudSync size='2em'/>&nbsp;sync</Button>
					<Button className = "btn btn-appbar" onClick={  download }><AiOutlineDownload size='2em'/>&nbsp;download</Button>
				</div>

				<div>
					<Button className = "btn btn-appbar" onClick={ () => documentProperties.toggleSelectingMode()}>{!documentProperties.selectingMode ? <><BsToggleOff size='2em'/>&nbsp;Select fields</> : <><BsToggleOn size='2em'/>&nbsp;unSelect fields</>}</Button>
					<Button className = "btn btn-appbar" onClick={ () => documentProperties.removeSelectedFields()}><AiOutlineDelete size='2em'/>&nbsp;Remove selected</Button>
				</div>

				<div>
					<Button className = "btn btn-appbar" onClick={ () =>{setScale(scale => scale - .1)} }><AiOutlineZoomOut size='2em'/></Button>
					<Button className = "btn btn-appbar" onClick={ () =>{setScale(scale => scale + .1)} }><AiOutlineZoomIn size='2em'/></Button>
					<Button className = {`btn ${documentProperties.canUndo ? 'btn-appbar' : 'btn-disabled' }`} onClick={ () =>{documentProperties.undoChange()} }><AiOutlineUndo size='2em'/></Button>
					<Button className = {`btn ${documentProperties.canRedo ? 'btn-appbar' : 'btn-disabled' }`} onClick={ () =>{documentProperties.redoChange()} }><AiOutlineRedo size='2em'/></Button>
				</div>

				<div>
					<Button className = "btn btn-appbar" onClick={ logout }><AiOutlineLogout size='2em'/>&nbsp;logout</Button>
				</div>
				</Toolbar>
			</AppBar>
		</div>
	)
}

async function getFormsList() {
	const response = await fetch(`${API_URL}/forms`, {
		method: 'Get',
		headers: { 
		  'Content-Type': 'application/json; charset=UTF-8'
		}
	  })
	  if(response?.ok){
		return response.json()
	  }
  }
  
  function getFormName(formsList, id){
	for(const pdfForm of formsList){
	  if(pdfForm.id === id){
	  return pdfForm.name
	  }
	}
	return ''
  }

export default Appbar
