import React from 'react'
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { isEqual } from 'lodash'

const agentAttris = ['SellerAgent', 'BuyerAgent', 'CoSellerAgent', 'CoBuyerAgent', 'SellerBroker', 'BuyerBroker', 'BuyerSalesManager', 'SellerSalesManager']

const addressAttrs = [
  'Seller',
  'Buyer',
  'Landlord',
  'Tenant',
  'Title',
  'BuyerLawyer',
  'SellerLawyer',
  'BuyerPowerOfAttorney',
  'SellerPowerOfAttorney',
  'TenantPowerOfAttorney',
  'LandlordPowerOfAttorney'
]

const lists = [
  [
    {
      label: 'Legal Full Name',
      value: ['legal_full_name']
    },

    {
      label: 'Email',
      value: ['email']
    },

    {
      label: 'Phone Number',
      value: ['phone_number']
    },

    {
      label: 'License Number',
      value: ['agent.license_number'],
      on: agentAttris
    },

    {
      label: 'MLS ID',
      value: ['agent.mlsid'],
      on: agentAttris
    },

    {
      label: 'License Number or MLS ID',
      value: ['agent.license_number', 'agent.mlsid'],
      on: agentAttris
    },

    {
      label: 'Commission %',
      value: ['commission_percentage'],
      on: agentAttris
    },

    {
      label: 'Commission $',
      value: ['commission_dollar'],
      on: agentAttris
    },

    {
      label: 'Designation',
      value: ['agent.designation'],
      on: agentAttris
    },

    {
      label: 'Stamp',
      value: ['stamp'],
      on: agentAttris
    },

    {
      label: 'Address (Full)',
      value: ['current_address.full'],
      on: addressAttrs
    },


    {
      label: 'Address (Line 1)',
      value: ['current_address.line1'],
      on: addressAttrs
    },


    {
      label: 'Address (Line 2)',
      value: ['current_address.line2'],
      on: addressAttrs
    },

    {
      label: 'Address (State)',
      value: ['current_address.state'],
      on: addressAttrs
    },

    {
      label: 'Address (City)',
      value: ['current_address.city'],
      on: addressAttrs
    },

    {
      label: 'Address (Post Code)',
      value: ['current_address.postcode'],
      on: addressAttrs
    },
  ],

  [
    {
      label: 'Company Title',
      value: ['company_title'],
      on: ['Title', 'SellerLawyer', 'BuyerLawyer']
    },

    {
      label: 'Brokerage Name',
      value: ['office_name', 'agent.office.name'],
      on: agentAttris
    },

    {
      label: 'Brokerage Email',
      value: ['office_email', 'agent.office.email'],
      on: agentAttris
    },

    {
      label: 'Brokerage Phone Number',
      value: ['office_phone', 'agent.office.phone'],
      on: agentAttris
    },

    {
      label: 'Brokerage Fax Number',
      value: ['office_fax', 'agent.office.fax'],
      on: agentAttris
    },

    {
      label: 'Brokerage License Number',
      value: ['office_license_number', 'agent.office.license_number'],
      on: agentAttris
    },

    {
      label: 'Brokerage MLS ID',
      value: ['office_mls_id', 'agent.office.mls_id'],
      on: agentAttris
    },

    {
      label: 'Brokerage License Number or MLS ID',
      value: ['office_license_number', 'agent.office.license_number', 'office_mls_id', 'agent.office.mls_id'],
      on: agentAttris
    },

    {
      label: 'Brokerage Address (Full)',
      value: ['office_address.full', 'agent.office.full_address'],
      on: agentAttris
    },


    {
      label: 'Brokerage Address (Line 1)',
      value: ['office_address.line1', 'agent.office.address'],
      on: agentAttris
    },


    {
      label: 'Brokerage Address (Line 2)',
      value: ['office_address.line2', 'agent.office.address_line2'],
      on: agentAttris
    },

    {
      label: 'Brokerage Address (State)',
      value: ['office_address.state', 'agent.office.state'],
      on: agentAttris
    },

    {
      label: 'Brokerage Address (City)',
      value: ['office_address.city', 'agent.office.city'],
      on: agentAttris
    },

    {
      label: 'Brokerage Address (Post Code)',
      value: ['office_address.postcode', 'agent.office.postal_code'],
      on: agentAttris
    }
  ],

  [
    {
      label: 'Broker Full Name',
      value: ['agent.office.broker.full_name'],
      on: agentAttris
    },

    {
      label: 'Broker Email',
      value: ['agent.office.broker.email'],
      on: agentAttris
    },

    {
      label: 'Broker Phone Number',
      value: ['agent.office.broker.phone_number'],
      on: agentAttris
    },

    {
      label: 'Broker License Number',
      value: ['agent.office.broker.license_number'],
      on: agentAttris
    },

    {
      label: 'Broker MLS ID',
      value: ['agent.office.broker.mlsid'],
      on: agentAttris
    },

    {
      label: 'Broker License Number or MLS ID',
      value: ['agent.office.broker.license_number', 'agent.office.broker.mlsid'],
      on: agentAttris
    }
  ]
]

const textTransform = 'none'

export default function Assignment(props) {
  const { onChange, attributes, role } = props

  return (
    <div>
      {lists.map((list, id) => {
        return (
          <ButtonGroup size="small" aria-label="xsmall button group" orientation="vertical">
            {list.map((item, key) => {
              if (item.on && role && !item.on.includes(role[0]))
                return null

              const selected = isEqual(attributes, item.value)
              const variant = selected ? 'contained' : 'outlined'
              return (
                <Button
                  onClick = {() => onChange(item.value)}
                  variant={variant}
                  style={{textTransform}}
                >
                  {item.label}
                </Button>
              )
            })}
          </ButtonGroup>
        )
      })}
    </div>
  )
}
