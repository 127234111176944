import { PDFAnnotation, PDFWidgetAnnotation, PDFName, PDFString, PDFHexString, PDFDict, PDFRef, PDFArray } from 'pdf-lib'

/*
 * This is a bug that originates from some poor pdf generators but manifests into others.
 * The same exact situation exists on Poppler as well, reported here:
 *
 * https://gitlab.freedesktop.org/poppler/poppler/-/issues/642
 *
 * Yep. Annotation exists in /Annots but no evidence of it on Acroform.
 * Thus, when saving the field, we can't find the corresponding field.
 *
 * This looks for such fields and adds their fields to the AcroField.
 */

const T = PDFName.of('T')

function fixOrphanWidgets(doc, form) {
  const fixed = new Set


  for(const page of doc.getPages()) {
    const annots = page.node.Annots()
    if (!annots) return

    const fieldsToAdd = []
    const annotsToAdd = []
    const size = annots.size()

    for (let idx = 0; idx < size; idx++) {
      const ref = annots.get(idx)
      const dict = annots.lookup(idx)

      let fieldName = dict.lookupMaybe(T, PDFString, PDFHexString)?.decodeText()
      let field, fieldRef

      /*
         * Alright, so, the AnnotationWidget has no FieldName. This is till not necessarily a problem.
         * Some widgets (Like Radio Buttons) don't value a `T` field (Which is where the field is stored).
         *
         * Instead, they refer to a parent Field widget.
         */

      if (!fieldName) {
        field = dict.lookupMaybe(PDFName.Parent, PDFDict)

        if (!field) {
          // throw 'Annotation has no name and no parent'
          continue
        }

        fieldName = field.lookupMaybe(T, PDFString, PDFHexString)?.decodeText()
        if (!fieldName) {
          throw 'Annotation has no name. It has a parent but that also lacks a name'
        }

        fieldRef = dict.get(PDFName.Parent)
      }

      const foundField = form.getFieldMaybe(fieldName)
      if (foundField)
        continue

      if (field) {
        const kids = field.lookupMaybe(PDFName.of('Kids'), PDFArray)

        for(let j = 0; j<= kids.size(); j++) {
          const kidRef = kids.get(j)
          const found = page.node.Annots().indexOf(kidRef)

          if (found > -1)
            continue

          const kidDict = kids.lookupMaybe(j, PDFDict)
          if (!kidDict)
            continue
          const kidType = kidDict.lookupMaybe(PDFName.Type, PDFName)
          if (kidType !== PDFName.of('Annot'))
            continue

          kids.remove(j)
        }

      }

      fieldsToAdd.push(fieldRef ?? ref)

      console.log('Fixed Orphan Widget', fieldName)
    }

    for(const field of fieldsToAdd)
      form.acroForm.addField(field)
  }
}

export default fixOrphanWidgets
