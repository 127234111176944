import React from 'react'
import {Selection, SelectionDefaults} from '../../inputs';

export default function Order(props) {
  const { onChange, order } = props

  const marks = [...Array(4).keys()]

  return (
    <Selection list={marks} onChange={onChange} selected={order} label={'Order'} orientation={'row'} margins={SelectionDefaults.margins} buttonsMargins={SelectionDefaults.buttonsMargins}/> 
  )
}