import { useState } from 'react';


export default function useCredentials() {
  const [credentials, setCredentials] = useState();

  const saveCredentials = (credentials) => {
    localStorage.setItem('credentials', JSON.stringify(credentials));
    setCredentials(credentials);
  };

  return {
    credentials,
    setCredentials: saveCredentials
  }
}