import React from 'react'
import { pick } from 'lodash'
import Format from '../../properties/Format/Date'

export default function Instructions(props) {
  const { instructions, setInstructions } = props

  const updateInstructions = newValues => {
    setInstructions({
     ...pick(instructions, [
      'type',
      'format'
     ]),
     ...newValues
   })
  }

  const handleFormatChange = e => {
    updateInstructions({
      format: e.target.value ? e.target.value : undefined
    })
  }

  return (
    <div style={{padding: '10px', backgroundColor: '#00000010'}}>
      <Format format={instructions?.format} onChange={handleFormatChange} />
    </div>
  )
}