import React from 'react'
import { pick } from 'lodash'
import Assignment from '../../properties/Assignment'
import Number from '../../properties/Number'
import Role from '../../properties/Role'
import Divider from '@mui/material/Divider';

export default function Instructions(props) {
  const { instructions, setInstructions } = props

  const updateInstructions = newValues => {
    setInstructions({
     ...pick(instructions, [
      'type',
      'role',
      'assignment',
      'number'
     ]),
     ...newValues
   })
  }

  const handleAssignmentChange = (value) => {
    updateInstructions({
      assignment: value
    })
  }

  const handleNumberChange = (number) => {
    updateInstructions({
      number
    })
  }

  const handleRoleChange = (value) => {
    const current = new Set([...instructions?.role || []])

    if ([...instructions?.role || []].indexOf(value) === -1) {
      current.add(value)
    }
    else {
      current.delete(value)
    }

    updateInstructions({
      role: Array.from(current)
    })
  }

  return (
    <div style={{padding: '10px', backgroundColor: '#00000010'}}>
      <Number number={instructions?.number} onChange={handleNumberChange} />
      <Divider variant="middle"/>
      <Assignment assignment={instructions?.assignment} onChange={handleAssignmentChange} />
      <Divider variant="middle"/>
      <Role roles={instructions?.role} onChange={handleRoleChange} />
    </div>
  )
}