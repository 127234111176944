import React from 'react'
import { pick } from 'lodash'
import Group from '../../properties/Group'
import Order from '../../properties/Order'
import Role from '../../properties/Role'
import Attribute from '../../properties/Attribute'
import Readonly from '../../properties/Readonly'
import Divider from '@mui/material/Divider';

export default function Instructions(props) {
  const { instructions, setInstructions } = props

  const updateInstructions = newValues => {
    setInstructions({
     ...pick(instructions, [
      'type',
      'role',
      'group',
      'order',
      'attributes',
      'readonly'
     ]),
     ...newValues
   })
  }

  const handleOrderChange = (v) => {
    updateInstructions({
      order: v === 0 ? undefined : v
    })
  }

  const handleGroupChange = (v) => {
    updateInstructions({
      group: v === 0 ? undefined : v
    })
  }

  const handleRoleChange = (value) => {
    const current = new Set([...instructions?.role || []])

    if ([...instructions?.role || []].indexOf(value) === -1) {
      current.add(value)
    }
    else {
      current.delete(value)
    }

    updateInstructions({
      role: Array.from(current)
    })
  }

  const handleReadonlyChange = (checked) => {
    updateInstructions({
      readonly: checked ? checked : undefined
    })
  }

  const handleAttributeChange = attributes => {
    updateInstructions({
      attributes
    })
  }

  return (
    <div style={{padding: '10px', backgroundColor: '#00000010'}}>
      
      <Role roles={instructions?.role} onChange={handleRoleChange} />
      <Divider variant="middle"/>
      <Attribute attributes={instructions?.attributes} onChange={handleAttributeChange} role={instructions?.role} />
      <Divider variant="middle"/>
      <Group group={instructions?.group} onChange={handleGroupChange} />
      <Divider variant="middle"/>
      <Order order={instructions?.order} onChange={handleOrderChange} />
      <Divider variant="middle"/>
      <Readonly checked={instructions?.readonly || false} onChange={handleReadonlyChange} />

    </div>
  )
}
