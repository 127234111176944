import React from 'react'
import {Selection, SelectionDefaults} from '../../inputs';

export default function Group(props) {
  const { onChange, group } = props

  const marks = [...Array(2).keys()]

  return (
    <Selection list={marks} onChange={onChange} selected={group} label={'Group'} orientation={'row'} margins={SelectionDefaults.margins} buttonsMargins={SelectionDefaults.buttonsMargins}/> 
  )
}