import React from 'react'

import Assignment from './types/Assignment'
import Context from './types/Context'
import Roles from './types/Roles'
import Role from './types/Role'
import Date from './types/Date'
import Number from './types/Number'

export default function Instructions(props) {
  const { instructions, setInstructions } = props

  const isAssignment = instructions?.type === 'Assignment'
  const isContext = instructions?.type === 'Context'
  const isRoles = instructions?.type === 'Roles'
  const isRole = instructions?.type === 'Role'
  const isDate = instructions?.type === 'Date'
  const isNumber = instructions?.type === 'Number'

  return (
    <div style={{padding: '10px', backgroundColor: '#fff'}}>
      { isAssignment && <Assignment instructions={instructions} setInstructions={setInstructions} /> }
      { isContext && <Context instructions={instructions} setInstructions={setInstructions} /> }
      { isRoles && <Roles instructions={instructions} setInstructions={setInstructions} /> }
      { isRole && <Role instructions={instructions} setInstructions={setInstructions} /> }
      { isDate && <Date instructions={instructions} setInstructions={setInstructions} /> }
      { isNumber && <Number instructions={instructions} setInstructions={setInstructions} /> }
    </div>
  )
}