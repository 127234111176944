import React, { useState } from 'react'
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { API_URL } from '../../config'
import checkFields from '../../checkFields'

function Save({setShowSave, open, form, pdfDocument, documentProperties}) {
  const [ message, setMessage ] = useState()
  const [ isSaving, setIsSaving ] = useState(false)
  const [ error, setError ] = useState()

  const save = async () => {
    const result = checkFields(pdfDocument)
    if (result.state === 'success'){
      setIsSaving(true)
      setError(null)
      documentProperties.forgetDeletingChanges()

      const pdf = new Blob([await pdfDocument.save()], {type: 'application/pdf'})

      const response = fetch(`${API_URL}/${form}`, {
        method: 'POST',
        body: pdf,
        headers: {
          'X-SAVE-MESSAGE': message || ''
        }
      })

      response.then(async res => {
        setIsSaving(false)

        if (res.status === 200) {
          setError(null)
          setMessage(null)
          setShowSave(false)
          documentProperties.showSnackbar('saved successfuly', 'success')
        } else {
          setError(await res.json())
          documentProperties.showSnackbar(`failed to save (${await res.json()})`, 'error')
        }}
      ).catch(e => {
        setError('Internal error')
        documentProperties.showSnackbar('failed to save (Internal error)', 'error')
        setIsSaving(false)}
      )
    } else {
      documentProperties.showSnackbar(`Saving failed:   (*)${result.fields.map(field=>`${field.name} is an ${field.message}`).join('        ,(*)')}`, result.state)
      documentProperties.selectingMode = true;
      documentProperties.setFieldsState(result.fields.map(field =>field.name), 'selected')
    }
  }

  return (
    <Dialog maxWidth="sm" fullWidth open={open}>
      <DialogTitle>Save</DialogTitle>
      <DialogContent>
        <TextField  value={message} onChange={e => setMessage(e.target.value)} rows={4} fullWidth placeholder="Why are you making this change?" />
      </DialogContent>
      <DialogActions>
        <Typography color="error">
          {error}
        </Typography>
        <Button disabled={isSaving} autoFocus color="secondary" variant="contained" onClick={() => setShowSave(false)}>
          Cancel
        </Button>
        <Button disabled={isSaving} color="primary" variant="contained" onClick={save}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Save