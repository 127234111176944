import { PDFName, PDFString } from 'pdf-lib'


function getOrCreateAA(acroField) {
  const found = acroField.dict.lookup(PDFName.of('AA'))
  if (found)
    return found

  const { context } = acroField.dict
  const AA = context.obj({})
  acroField.dict.set(PDFName.of('AA'), AA)

  return AA
}

function getOrCreateC(AA) {
  const found = AA.lookup(PDFName.of('C'))
  if(found)
    return found

  const { context } = AA
  const C = context.obj({})
  AA.set(PDFName.of('C'), C)

  return C
}

function setInstructions(acroField, instructions) {
  const AA = getOrCreateAA(acroField)
  const C = getOrCreateC(AA)
  C.dict.set(PDFName.of('S'), PDFName.of('JavaScript'))
  C.dict.set(PDFName.of('JS'), PDFString.of(JSON.stringify(instructions, 2, 2)))
}

  export default setInstructions