/*
 * Sometimes, we have some fields, who don't have a P field. That means the field is defined, but it's not defined
 * in which page it should appear. Other PDF editors seem to ignore such fields. However, as if now, PDF-Lib will
 * throw an error like this: Could not find page for PDFRef 332 0 R
 * This will happening dueing flattening process.
 * Therefore, this function will try to find such fields and remove them from the form.
 */
function removeInvalidFields(doc, form) {
	const fields = form.getFields()
	
	for (const field of fields) {
		const widgets = field.acroField.getWidgets()
		
		//we should remove fields with no annotations
		if (widgets.length === 0) {
			form.removeField(field);
		}

		for(const widget of widgets) {
			try {
				form.findWidgetPage(widget)
			} catch(e) {
				try {
					form.acroForm.removeField(field.acroField)
				} catch(e2) {
					// Removing the field itself can also be problematic. We get errors like this:
					// Error: Tried to remove inexistent field RB10
					// However, our attempts may have been enough already 
				}
			}
		}
	}	
}

export default removeInvalidFields