import React, { useState, useEffect, useCallback} from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Instructions from './Instructions'
import Code from './Code'
import setInstructions from '../process/set-instructions'
import getInstructions from '../process/get-instructions'
import { AiOutlineCopy, AiFillEdit } from 'react-icons/ai'
import {Selection} from './Instructions/inputs';
import {cheatSheet} from '../Pdf/Page/Annotation/Text/cheatSheet'
import Divider from '@mui/material/Divider';
const list = ['None','Context','Roles','Role','Assignment','Date','Number']

let i = 0

export default function AnnotationEditor(props) {
  const { annotations, pdfDocument, documentProperties} = props
  const [json, setJSON] = useState()

  /*const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      if (json !== ''){
        documentProperties.checkFieldsInstructions(annotations?.map(annotation=>annotation.fieldName) || []);
        console.log(annotations)
      }
      setAnnotations([])
    }
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", escFunction);

    return () => {
      window.removeEventListener("keydown", escFunction);
    };
  }, [escFunction]);*/

  const updateJSON = async json => {
    setTimeout(() => {documentProperties.checkFieldsInstructions(annotations.map(annotation => annotation.fieldName));}, 50)
    checkFastMapCode(json)
    documentProperties.setFieldsInstructions(annotations.map(annotation => annotation.fieldName), json)
    pdfDocument.__id = i++
  }

  function copyJSON(json){
    navigator.clipboard.writeText( JSON.stringify(json) );
  }

  async function pasteJSON(updateJSON){
    try{
      const json = JSON.parse(await navigator.clipboard.readText());
      updateJSON(json);
    } catch(e){
      
    }
  }

  useEffect(() => {
    if (annotations.length === 0)
      return

    const field = pdfDocument.getForm().getField(annotations[0].fieldName)
    const instructions = getInstructions(field.acroField)

    if(annotations.every(annot => JSON.stringify(getInstructions(pdfDocument.getForm().getField(annot.fieldName).acroField)) === JSON.stringify(instructions))){
      setJSON(instructions)
      documentProperties.checkFieldsInstructions(annotations.map(annotation => annotation.fieldName));
    } else {
      setJSON('')
    }
  }, [annotations, pdfDocument, documentProperties])

  const handleChange = value => {
    updateJSON(value === 'None' ? '' : {
      type: value
    })
  }

  const handleFastMapChange = e => {
    const value = Number(e.target.value)
    if(value <= 20 && value > 0){
      updateJSON(cheatSheet[value-1])
    } else{
      updateJSON('')
    }
  }

  function checkFastMapCode(instructions) {
    for(const item in cheatSheet){
      const equal = deepCompare(cheatSheet[item],removeNumberingProperties(instructions));
      if(equal){
        documentProperties.showSnackbar(`the code for this mapping is ${Number(item)+1}`, 'success')
        return Number(item)+1
      }
    }
    return ''
  }

  const removeNumberingProperties = (obj) => {
    let {'number': omit1, 'group': omit2, 'order': omit3, ...res} = obj
    return res
  }

  const deepCompare = (arg1, arg2) => {
    if (Object.prototype.toString.call(arg1) === Object.prototype.toString.call(arg2)){
      if (Object.prototype.toString.call(arg1) === '[object Object]' || Object.prototype.toString.call(arg1) === '[object Array]' ){
        if (Object.keys(arg1).length !== Object.keys(arg2).length ){
          return false;
        }
        return (Object.keys(arg1).every(function(key){
          return deepCompare(arg1[key], arg2[key]);
        }));
      }
      return (arg1===arg2);
    }
    return false;
  }

  if (annotations.length === 0)
    return null

  const style = {
    position: 'fixed',
    top: '10%',
    right: '3%',
    width: '43%',
    height: '80%',
    boxShadow:  '3px 3px 10px 1px #00000050',
    border: '1px solid #000000',
    borderRadius: '20px',
    padding: '5px',
    zIndex: 100,
    background: '#fff',
    overflowY: 'scroll'
  }

  return (
    <div style={style}>
      <Button className = "btn btn-annotation" onClick={ ()=>{copyJSON(json)}}><AiOutlineCopy size='2em'/>&nbsp;Copy</Button>
      <Button className = "btn btn-annotation" onClick={ ()=>{pasteJSON(updateJSON)}}><AiFillEdit size='2em'/>&nbsp;Paste</Button>
      <TextField value={json ? checkFastMapCode(json) : ''} onChange={handleFastMapChange} label="Fast Map" size='small'/>
      <Divider variant="middle" />
      <Selection list={list} onChange={handleChange} selected={json?.type} label={'Type'} orientation={'row'} margins={'50px 0 0 5px'} buttonsMargins={'0 10px 0 0'}/>
      <Divider variant="middle" />
      <Instructions instructions={json} setInstructions={updateJSON} />
      <Code instructions={json} setInstructions={updateJSON} />
    </div>
  )
}
