import React, { useEffect, useRef, useState } from 'react'
import Annotation from './Annotation'

function Page(props) {
  const { pdf, pageNumber, setAnnotations, pdfDocument, scale, documentProperties } = props

  const canvas = useRef(null)
  const [PageAnnotations, setPageAnnotations] = useState([])
  const [viewport, setViewport] = useState()

  useEffect(() => {
    const load = async () => {

      const page = await pdf.getPage(pageNumber)
      const viewport = page.getViewport({ scale: scale })

      setViewport(viewport)

      const $canvas = canvas.current
      $canvas.width = viewport.width
      $canvas.height = viewport.height
      const context = $canvas.getContext('2d')

      page.render({
        canvasContext: $canvas.getContext('2d'),
        viewport
      })

      if (context) {
        context.clearRect(0, 0, $canvas.width, $canvas.height);
        context.beginPath();
      }
    }
    load()
  }, [pdf, pageNumber, canvas, documentProperties, scale])

  useEffect(() => {
    const load = async () => {
      const page = await pdf.getPage(pageNumber)
  
      const PageAnnotations = await page.getAnnotations()
      setPageAnnotations(PageAnnotations)
    }
    load()
  }, [pdf, pageNumber, canvas, documentProperties])

  const $PageAnnotations = PageAnnotations.map((a, i) => {
    return (
      <Annotation key={i} annotation={a} viewport={viewport} setAnnotations={setAnnotations} pdfDocument={pdfDocument} documentProperties={documentProperties} />
    )
  })

  const style = {
    position: 'absolute',
    width: viewport?.width,
    height: viewport?.height
  }

  return (
    <div>
      <div style={style}>
        { $PageAnnotations.length > 0 && $PageAnnotations }
      </div>
      <canvas ref={canvas} />
    </div>
  )
}

export default React.memo(Page)