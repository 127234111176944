import { PDFTextField } from 'pdf-lib'
import getInstructions from './process/get-instructions'
const checkingArray =[
    {
        name: 'Role',
        required: {'roles': 'role', 'attributes': 'attributes'}
    },
    {
        name: 'Roles',
        required: {'roles': 'role', 'attributes': 'attributes'}
    },
    {
        name: 'Assignment',
        required: {'assignment': 'assignment', 'roles': 'role'}
    },
    {
        name: 'Context',
        required: {'context': 'context'}
    }
]

const readonlyMappingsArray = [
    '{"type":"Role","role":["PrimaryAgent"],"attributes":["stamp"]}',
    '{"type":"Role","role":["PrimaryAgent"],"attributes":["legal_full_name"]}',
    '{"type":"Role","role":["SellerAgent"],"attributes":["office_address.full"]}',
    '{"type":"Role","role":["SellerAgent"],"attributes":["agent.office.phone"]}',
]

const dateFormatArray = [
    "MMMM DD, YYYY",
    "MMMM",
    "DD",
    "YYYY",
    "YY",
    "MMMM DD",
    "DD MMMM",
    "MMMM, YYYY"
]

export default function checkFields(document){
    const form = document.getForm();
    const fields = form.getFields();
    const failedFields = []
    for (const field of fields) {
        if (!field instanceof PDFTextField){
            continue
        }
        const instructions = getInstructions(field.acroField);
        let result = checkFieldError(instructions)
        if (result.state !== 'success') {
            failedFields.push({name: field.acroField.getPartialName(), message: result.message})
        }
      }
    return {state: failedFields.length === 0 ? 'success' : 'error', fields: failedFields};
}

export function checkFieldError(instructions){
    try{
        if (!instructions){
            return {state: 'success', message: 'annotation with no mapping'};
        }
        for(const annotation of checkingArray){
            for(const field in annotation.required){
                if(instructions.type === annotation.name){
                    const result = checkArray(annotation.name, instructions[annotation.required[field]], field)
                    if(result){
                        return result;
                    }
                }
            }
        }
    } catch(e){
        console.log(e);
    }
    return {state: 'success', message: 'annotation with no issues'};
}

function checkArray(label, array, arrayLabel){
    if((array?.length || 0) === 0){
        return {state: 'error', message: `${label} annotation with no ${arrayLabel}`};
    }
    return false
}

export function checkFieldWarning(instructions){
    try{
        if (!instructions){
            return {state: 'success', message: 'annotation with no mapping'};
        }

        for(const json of readonlyMappingsArray){
            if(deepCompare(json, removeNumberingReadonlyProperties(instructions))){
                if(!instructions.readonly){
                    return {state: 'warning', message: `annotation with this mapping might be readonly`, error:'readonly'}
                }
            }
        }

        if(instructions.group === 1){
            if(!(instructions.order > 0)){
                return {state: 'warning', message: `annotation with group should have an 'order'`, error: 'order'}
            }
        }

        if(instructions.order >= 1){
            if(instructions.group !== 1){
                return {state: 'warning', message: `annotation with order should have 'group' set to 1`, error: 'order'}
            }
        }

        if(instructions.type === 'Assignment' || instructions.type === 'Role'){
            if(!(instructions.number >= 0)){
                return {state: 'warning', message: `annotation with this mapping might need a 'number'`, error: 'number'}
            }
        }

        if((instructions.type === 'Context' && ["expiration_date", 'closing_date', 'list_date'].indexOf(instructions.context) !== -1) || instructions.type === 'Date'){
                if( dateFormatArray.indexOf(instructions.format) === -1){
                    return {state: 'warning', message: `annotation with no or invalid Date format`, error: 'format'}
                }
        }
    } catch(e){
        console.log(e);
    }
    return {state: 'success', message: 'annotation with no issues'};
}

const removeNumberingReadonlyProperties = (obj) => {
    let {'number': omit1, 'group': omit2, 'order': omit3, 'readonly': omit4, ...res} = obj
    return res
}

const deepCompare = (arg1, arg2) => {
    if (Object.prototype.toString.call(arg1) === Object.prototype.toString.call(arg2)){
        if (Object.prototype.toString.call(arg1) === '[object Object]' || Object.prototype.toString.call(arg1) === '[object Array]' ){
        if (Object.keys(arg1).length !== Object.keys(arg2).length ){
            return false;
        }
        return (Object.keys(arg1).every(function(key){
            return deepCompare(arg1[key], arg2[key]);
        }));
        }
        return (arg1===arg2);
    }
    return false;
}