import { PDFDocument } from 'pdf-lib'

// Flatten the form once, so if there's any errors with flattening, we capture it during the mapping process.
// Otherwise, we'll deploy the form, and users will notice the issue during actual usage.

async function test(doc) {
  const newDoc = await PDFDocument.load(await doc.save({
    updateFieldAppearances: true
  }))	

  newDoc.getForm().flatten()
}


export default test