const {
  PDFCheckBox, rgb
} = require('pdf-lib');

/**
 * 
 * @param {*} doc 
 * @returns fields arranged from top right to bottom left for every page
 * 1) Devide fields into pages and arrange them accourding to their y coordinate
 * 2) Add checkbox fields on the same vertical line to a single list
 * 3) If two fields are almost in the same horizontal line, the first field will be
 * the left one
 * 4) clone the fields so that the tabbing works correctly
 */

export function rearangeFields(doc) {
  const form = doc.getForm();
  const fields = form.getFields();

  //devide fields to pages
  //fields are added as [field,[x,y]] where x, y are the coordinates
  const fieldsGroupedByPage = devideByPage(doc, fields);

  //arrange each page by y axis of the fields
  arrangePagesByFieldYAxis(fieldsGroupedByPage)

  //group checkboxes on the same vertical line by closeness of their y axis
  //[field0],[checkbox0, checkbox1] ,[checkbox2], [field4]
  const fieldsGroupedByCloseness = groupCheckboxesByCloseness(fieldsGroupedByPage);

  //remove and then add fields to the pdf file in the correct order
  cloneFields(doc, fieldsGroupedByCloseness)

  return fieldsGroupedByCloseness;
}

function devideByPage(doc, fields){
  const pagesCache = {}
  const coordinatesCache = {}
  const fieldsGroupedByPage = {}
  /**
   * { 
   * '0':[
   *      [field1,[x1,y1]], [field2,[x2,y2]]
   *   ]
   * }
   */
   fields.forEach(field => {
    const fieldRef = field.ref;
    const page = memoizedpage(doc, pagesCache, fieldRef);
    const cordinates = memoizedcoordinates(coordinatesCache, fieldRef, field)
    fieldsGroupedByPage[page] = fieldsGroupedByPage[page]?.concat([[field, cordinates]]) || [[field, cordinates]]
  })
  return fieldsGroupedByPage
}

//arranges every page's fields accourding to their y coordinate
function arrangePagesByFieldYAxis(fieldsGroupedByPage){
  for(const pageIndex in fieldsGroupedByPage){
    fieldsGroupedByPage[pageIndex].sort((a, b) => b[1][1]-a[1][1]);
  }
}

/**
 *returns fields grouped by their page number
 *and every page is grouped by closeness of the fields 
  * {
  *  '0':[
  *      [field1,field3,field43], [field2],....
  *   ],
  *  ....
  * }
*/
function groupCheckboxesByCloseness(fieldsGroupedByPage){
  const fieldsGroupedByCloseness = {}
  for(const page in fieldsGroupedByPage){
    fieldsGroupedByCloseness[page] = groupArray(fieldsGroupedByPage[page])
  }
  return fieldsGroupedByCloseness
}

function groupArray(arr){
    const arrangedArr = []
    for (const field of arr){
      //only group checkbox fields
      if(field[0] instanceof PDFCheckBox){
        let index = arrangedArr.findIndex((element)=>{return compareCordinatesCloseness(element[element.length-1][1],field[1])})
        if(index > -1){
          arrangedArr[index].push(field)
          continue;
        }
      }
      const index = getNormalFlowIndex(arrangedArr, field)
      arrangedArr.splice(index + 1, 0, [field])
    }
  return arrangedArr
}

//the top fields in y axis are the first to be tabbed
//if two fields have the y axis, the first is the one to the left
function getNormalFlowIndex(arr, field){
  const firstIndex = arr.findIndex((element)=>{return ( Math.abs(element[0][1][1] - field[1][1]) < 8 )})

  let index = arr.length
  if(firstIndex>=0){
    index = firstIndex - 1;
    arr.slice(firstIndex).forEach((group, idx) =>{
      if(group[0][1][0] < field[1][0]){
        index = idx + firstIndex;
      }
    })
  }
  return index
}

function compareCordinatesCloseness(cor1, cor2){
  //TODO: this threshold should change accourding to the document
  const closenessThreshold = 20;
  //not close if the x axis is far
  if(Math.abs(cor1[0] - cor2[0]) > 10){
    return false;
  }
  //not close if the difference in y axis is larger than the threshold
  if(Math.abs(cor1[1] - cor2[1]) > closenessThreshold){
    return false;
  }
  return true;
}

function cloneFields(doc, fieldsGroupedByCloseness){
  //cloneFields
  for(const pageIndex in fieldsGroupedByCloseness){
    const page = fieldsGroupedByCloseness[pageIndex]
    const fieldPage = doc.getPage(parseInt(pageIndex));
    for(const groupIndex in page){
      const group = page[groupIndex];

      group.forEach(field => { 
        cloneField(fieldPage, field[0])
      })
    }
  }
}

//we try to remove the fields from the page's annotation dictionary
//and then add them to the dictionary again
function cloneField(page, field) {
  page.node.removeAnnot(field.ref);
  page.node.addAnnot(field.ref);
}

//trys to find the page for an annotation by going through all pages' annotations dictionary
//if it fails it will look in the pages for the field's refreference (This should be added to pdf-lib)
function findPageForAnnotationRef(doc, ref) {
  let page = doc.findPageForAnnotationRef(ref);
  if (!page) {
    const pages = doc.getPages();
    for (let i = 0, l = pages.length; i < l; i++) {
      if (pages[i].node.context.indirectObjects.has(ref)) {
        page = pages[i];
      }
    }
  }
  return page
}

//Used to cache annotation pages
function memoizedpage(doc, cache, ref) {
  if (!cache[ref]) {
    const result = doc.getPages().indexOf(findPageForAnnotationRef(doc, ref))
    cache[ref] = result;
    return result
  }
  return cache[ref]
}

//Used to cache fields' coordinates
function memoizedcoordinates(cache, ref, field) {
  if (!cache[ref]) {
    const result = [parseInt(field.acroField.getWidgets()[0]?.getRectangle()?.x), parseInt(field.acroField.getWidgets()[0]?.getRectangle()?.y)]
    cache[ref] = result;
    return result
  }
  return cache[ref]
}

/**
 * 
 * @param {*} doc 
 * @param {*} fieldsGroupedByCloseness 
 * Draws a rectangle on grouped checkbox fields
 * Aslo writes the a number on each field showing the ordering of the fields
 */
 export function testArrangment(doc, fieldsGroupedByCloseness){
  let i = 0
  for(const pageIndex in fieldsGroupedByCloseness){
    const page = fieldsGroupedByCloseness[pageIndex]
    for(const groupIndex in page){
      const group = page[groupIndex];
      const bounds = getGroupBounds(group)
      if (group.length >= 2){
        highlightGroupedCheckboxes(doc, parseInt(pageIndex), rgb(1,	.33,	i*.33), bounds)
        i = i===2?0:i+1;
      } else {
        drawNumbering(doc, parseInt(pageIndex), groupIndex, bounds)
      }
    }
  }
}

//get {x,y,width,height} of a grouped fields
function getGroupBounds(group){
  const x = group[0][1][0] - 3;
  const y = group[group.length-1][1][1];
  let height, width
  if (group.length >= 2){
    const checkBoxHeight = group[0][1][1] - group[1][1][1]
    height = Math.abs(y - group[0][1][1] - checkBoxHeight)
    width = 16
  } else {
    height = 0
    width = 0
  }
  return {x: x, y: y, width: width, height: height}
}

function drawNumbering(doc, pageIndex, groupIndex, bounds){
  doc.getPage(pageIndex).drawText(String(groupIndex),{
    x: bounds.x,
    y: bounds.y,
    size:16
  })
}

//draws a rectangle around grouped checkboxes
function highlightGroupedCheckboxes(doc, pageIndex, color, bounds){
  doc.getPage(pageIndex).drawRectangle({
    x: bounds.x,
    y: bounds.y,
    width: bounds.width,
    height: bounds.height,
    borderColor: color,
    color: color,
  })
}

//module.exports = {rearangeFields: rearangeFields, testArrangment: testArrangment}
