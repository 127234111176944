import React, { useRef, useEffect } from 'react'
import parseAppearance from '../parse-appearance'
import {cheatSheet} from './cheatSheet'
import {checkFieldError, checkFieldWarning} from '../../../../checkFields'

const getColor = (instructions, documentProperties, fieldName ) => {
  if (documentProperties.selectingMode){
    return documentProperties.mapping[fieldName]?.state === 'selected' ? 'rgba(255, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.5)' ; 
  }

  if (!instructions){
    return 'rgba(255, 0, 0, 0.5)'
  }

  if (checkFieldError(instructions).state !== 'success' || checkFieldWarning(instructions).state!=='success'){
    return 'rgba(0, 0, 255, .5)' 
  }

  if (instructions.type === 'Assignment'){
    return 'rgba(240, 205, 78, .9)' 
  }

  return 'rgba(0, 255, 0, 0.5)'
}

function Annotation(props) {
  const { annotation, width, height, left, top, setAnnotations, pdfDocument, instructions, documentProperties, fieldName} = props

  const input = useRef()

  useEffect(() => {
    if (!input.current)
      return

    const appearance = parseAppearance(annotation.defaultAppearance)

    const $input = input.current
    $input.style.position = 'absolute'
    $input.style.left = `${left}px`
    $input.style.top = `${top}px`
    $input.style.width = `${width}px`
    $input.style.height =  `${height}px`
    $input.style.color = appearance.color
    $input.style.fontWeight = appearance.bold ? 'bold' : 'normal'
    $input.style.border = 'none'
    $input.style.fontSize = appearance.size ? `${appearance.size}px` : 'auto'

    const color = getColor(instructions, documentProperties, fieldName);
    $input.style.backgroundColor = color
    if (documentProperties.mapping[fieldName]?.state === 'deleted'){
      $input.style.display = 'none';
    }
  }, [input, annotation, pdfDocument, instructions, width, height, left, top, documentProperties, fieldName])

  const updateJSON = async json => {
    documentProperties.setFieldsInstructions([annotation.fieldName], json)
    pdfDocument.__id += 1
  }

  const clicked = () => {
    if(documentProperties.selectingMode){
      documentProperties.toggleFieldSelection(fieldName);
      setAnnotations(annotations=>{const index = annotations.indexOf(annotation)
        if(index === -1){
          return annotations.concat([annotation])
        }
        annotations.splice(index, 1)
        return annotations})
    } else{
      documentProperties.checkFieldsInstructions([fieldName]);
      setAnnotations([annotation])
    }
  }

  const handleChange = (e) => {
    const value = Number(e.target.value)
    if(value <= 20 && value > 0){
      setTimeout(() => {updateJSON(cheatSheet[value-1])}, 500)
    }
  }

  return (
    <input autoComplete="off" ref={input} onClick={clicked} onChange={handleChange}/>
  )
}

export default React.memo(Annotation)