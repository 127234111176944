import React from 'react'
import { pick } from 'lodash'
import Group from '../../properties/Group'
import Order from '../../properties/Order'
import Readonly from '../../properties/Readonly'
import DisableAutoPopulate from '../../properties/DisableAutoPopulate'
import Context from '../../properties/Context'
import Format from '../../properties/Format/Date'
import Divider from '@mui/material/Divider';

export default function Instructions(props) {
  const { instructions, setInstructions } = props

  const updateInstructions = newValues => {
    setInstructions({
     ...pick(instructions, [
      'type',
      'context',
      'group',
      'order',
      'readonly',
      'disableAutoPopulate',
      'format'
     ]),
     ...newValues
   })
  }

  const handleOrderChange = (v) => {
    updateInstructions({
      order: v === 0 ? undefined : v
    })
  }

  const handleGroupChange = (v) => {
    updateInstructions({
      group: v === 0 ? undefined : v
    })
  }

  const handleReadonlyChange = (checked) => {
    updateInstructions({
      readonly: checked ? checked : undefined
    })
  }

  const handleDisableAutopopulateChange = (value) => {
    updateInstructions({
      disableAutopopulate: value ? value : undefined
    })
  }

  const handleContextChange = (value) => {
    updateInstructions({
      context: value
    })
  }

  const handleFormatChange = e => {
    updateInstructions({
      format: e.target.value ? e.target.value : undefined
    })
  }

  return (
    <div style={{padding: '10px', backgroundColor: '#00000010'}}>
      <Context context={instructions?.context} onChange={handleContextChange} /> 
      <Divider variant="middle"/>
      <Format format={instructions?.format} onChange={handleFormatChange} />
      <Divider variant="middle"/>
      <Group group={instructions?.group} onChange={handleGroupChange} />
      <Divider variant="middle"/>
      <Order order={instructions?.order} onChange={handleOrderChange} />
      <Divider variant="middle"/>
      <Readonly checked={instructions?.readonly || false} onChange={handleReadonlyChange} />
      <Divider variant="middle"/>
      <DisableAutoPopulate checked={instructions?.disableAutopopulate || false} onChange={handleDisableAutopopulateChange} />
    </div>
  )
}