import React, {useState} from 'react'
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';

export const SelectionDefaults = {
    'margins': '20px 0 20px 5px',
    'columnsMargins': '0 10px 10px 0',
    'buttonsMargins': '0 10px 0 0'
}

export const MultipleSelectionDefaults = {
    'margins': '20px 0 20px 5px',
    'columnsMargins': '0 10px 10px 0',
    'buttonsMargins': '0 0 0 10px'
}

export const ToggleButtonDefaults = {
    'margins': '20px 0 20px 5px'
}

export const FormatInputDefaults = {
    'margins': '20px 0 20px 5px'
}

const textTransform = 'none'

export function ButtonGroup(props) {
    const { list, selected, onChange, orientation, margins , buttonsMargins } = props
    
    const toggleButtonsArr = list.map(value=>
        <Button variant={ selected?.indexOf(value) > -1 ? "contained" : "outlined" } onClick = {() => {onChange(value)}} key={value} style={{margin: buttonsMargins, textTransform} } size={"small"}>
            {value}
        </Button>
    )

    return (
        <div style={{display: 'flex',justifyContent: 'flix-start', flexWrap:'wrap', flexDirection:orientation, margin: margins}}>
            {toggleButtonsArr}
        </div>
    )
}

export function Selection(props) {
    const { list, selected, columnsNumber = 1, label, onChange, orientation, margins, columnsMargins='0 0 0 0', buttonsMargins } = props

    const handleChange = (value) => {
        onChange(value)
    }

    return (
    <div style={{display: 'flex', margin: margins}}>
        <Divider>
            <Chip label={label} color={selected !== undefined ? "primary" : "secondary"}/>
        </Divider>
        {columnsNumber > 1 ?
        <div style={{display: 'flex',justifyContent: 'space-between', flexWrap: 'wrap', margin: columnsMargins}}>
            <SelectionDialog list={list} onChange={handleChange} columnsNumber={columnsNumber} selected={selected} label={label} orientation={orientation} margins={margins} columnsMargins={columnsMargins} buttonsMargins={buttonsMargins}/>
        </div>
        : <ButtonGroup list={list} onChange={handleChange} selected={[selected]} orientation={orientation} margins={'0 0 0 0'} buttonsMargins={buttonsMargins} />
        }
    </div>
    )
}

export function MultipleSelection(props) {
    const { list, onChange, selected, label, columnsNumber=1 , orientation, margins, columnsMargins, buttonsMargins } = props

    return (
    <div style={{display: 'flex', margin: margins, flexDirection: orientation, alignItems: orientation === 'column' ? 'center' : 'flex-start'}}>
        <Divider>
            <Chip label={label} color={selected?.length > 0 ? "primary" : "secondary"}/>
        </Divider>
        {columnsNumber > 1 ?
            <MultipleSelectionDialog list={list} onChange={onChange} columnsNumber={columnsNumber} selected={selected} label={label} orientation={orientation} margins={margins} columnsMargins={columnsMargins} buttonsMargins={buttonsMargins}/>
        : <ButtonGroup list={list} onChange={onChange} selected={[]} orientation={orientation} margins={columnsMargins} buttonsMargins={buttonsMargins} />
        }
    </div>
    )
}

export function SelectionDialog(props) {
    const { list, onChange, selected, label, columnsNumber=1, orientation, margins, columnsMargins, buttonsMargins } = props
    const [open, setOpen] = useState(false)
    
    const columnsLength = Math.ceil(list.length/columnsNumber);
    const lists = [...Array(columnsLength).keys()].map(n => list.slice(n*columnsLength,(n+1)*columnsLength))

    const handleClose = () => {
        setOpen(false)
    };
  
    const handleListItemClick = (value) => {
        onChange(value);
        setOpen(false)
    };
  
    return (
        <div>
            <Button variant={"contained"} onClick = {() => {setOpen(true)}} color={selected?.length > 0 ? "primary" : "secondary"} style={{textTransform}}>
                {selected !== undefined ? selected : 'select'}
            </Button>
            <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={false}>
                <DialogContent>
                    <div style={{display: 'flex',justifyContent: 'space-between', flexWrap: 'wrap', margin: columnsMargins}}>
                        {lists.map((list, i) =>
                            <ButtonGroup key={i} list={list} onChange={handleListItemClick} selected={[selected]} orientation={'column'} margins={columnsMargins} buttonsMargins={buttonsMargins} />
                        )}
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export function MultipleSelectionDialog(props) {
    const { list, onChange, selected, label, columnsNumber=1, columnsMargins, buttonsMargins } = props
    const [open, setOpen] = useState(false)
    
    const columnsLength = Math.ceil(list.length/columnsNumber);
    const lists = [...Array(columnsLength).keys()].map(n => list.slice(n*columnsLength,(n+1)*columnsLength))

    const handleClose = () => {
        setOpen(false)
    };
  
    const handleListItemClick = (value) => {
        onChange(value);
    };
  


    return (
        <div>
            <Button variant={"contained"} onClick = {() => {setOpen(true)}} color={selected?.length > 0 ? "primary" : "secondary"}>
                {selected?.length > 0 ? selected.join('   ,   ') : 'select'}
            </Button>
            <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={false}>
                <DialogContent>
                    <div style={{display: 'flex',justifyContent: 'space-between', flexWrap: 'wrap', margin: columnsMargins}}>
                        {lists.map((list, i) =>
                            <ButtonGroup key={i} list={list} onChange={handleListItemClick} selected={selected} orientation={'column'} margins={columnsMargins} buttonsMargins={buttonsMargins} />
                        )}
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export function ToggleButton(props) {
    const { checked, onChange, label, margins } = props   

    const handleChange = () => {
        onChange(!checked)
    }

    return (
        <div style={{display: 'flex', margin: margins}}>
            <Divider>
                <Chip label={label} color={checked ? "primary" : "secondary"}/>
            </Divider>

            <Switch onChange={handleChange} checked={checked}/>
        </div>
    )
}

export function FormatInput(props) {
    const { format, onChange, label, margins } = props

    return (
        <div style={{display: 'flex', margin: margins}}>
            <Divider>
                <Chip label={label} color={format ? "primary" : "secondary"}/>
            </Divider>

            <TextField onChange={onChange} value={format} label="MMMM DD, YYYY" size='small'/>
            <Button variant={"contained"} onClick = {onChange} value={"MMMM DD, YYYY"} color={format === "MMMM DD, YYYY" ? "primary" : "secondary"} size="small" style={{marginLeft:'20px'}}>"MMMM DD, YYYY"</Button>

            <Button variant={"contained"} onClick = {onChange} value={"NumeralCurrency"} color={format === "NumeralCurrency" ? "primary" : "secondary"} size="small" style={{marginLeft:'20px'}}>"NumeralCurrency"</Button>
        </div>
    )
}
