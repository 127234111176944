import React from 'react'
import {Selection, SelectionDefaults} from '../../inputs';

export default function Number(props) {
  const { onChange, number } = props

  const marks = [...Array(4).keys()]

  return (
    <Selection list={marks} onChange={onChange} selected={number} label={'Number'} orientation={'row'} margins={SelectionDefaults.margins} buttonsMargins={SelectionDefaults.buttonsMargins}/>
  )
}