import { PDFName } from 'pdf-lib'

function getInstructions(acroField) {
  const AA = acroField.dict.lookup(PDFName.of('AA'))
  const C = AA?.lookup(PDFName.of('C'))
  const JS = C?.lookup?.(PDFName.of('JS'))?.asString()?.replace(/\\n/g, '')

  if (!JS) return null

  try {
    return JSON.parse(JS)
  } catch(e) {
    console.log('Cannot parse', acroField, e)
    return null
  }
}

export default getInstructions